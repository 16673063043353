exports.components = {
  "component---src-pages-3rd-party-licenses-js": () => import("./../../../src/pages/3rd-party-licenses.js" /* webpackChunkName: "component---src-pages-3rd-party-licenses-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-academy-js": () => import("./../../../src/pages/academy.js" /* webpackChunkName: "component---src-pages-academy-js" */),
  "component---src-pages-access-management-js": () => import("./../../../src/pages/access-management.js" /* webpackChunkName: "component---src-pages-access-management-js" */),
  "component---src-pages-access-requests-js": () => import("./../../../src/pages/access-requests.js" /* webpackChunkName: "component---src-pages-access-requests-js" */),
  "component---src-pages-access-reviews-js": () => import("./../../../src/pages/access-reviews.js" /* webpackChunkName: "component---src-pages-access-reviews-js" */),
  "component---src-pages-blog-category-all-blogs-js": () => import("./../../../src/pages/blog/category/all-blogs.js" /* webpackChunkName: "component---src-pages-blog-category-all-blogs-js" */),
  "component---src-pages-blog-category-miscellaneous-js": () => import("./../../../src/pages/blog/category/miscellaneous.js" /* webpackChunkName: "component---src-pages-blog-category-miscellaneous-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-browser-extension-js": () => import("./../../../src/pages/browser-extension.js" /* webpackChunkName: "component---src-pages-browser-extension-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-catalog-js": () => import("./../../../src/pages/catalog.js" /* webpackChunkName: "component---src-pages-catalog-js" */),
  "component---src-pages-community-js": () => import("./../../../src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-desktop-agent-js": () => import("./../../../src/pages/desktop-agent.js" /* webpackChunkName: "component---src-pages-desktop-agent-js" */),
  "component---src-pages-events-all-js": () => import("./../../../src/pages/events/all.js" /* webpackChunkName: "component---src-pages-events-all-js" */),
  "component---src-pages-events-index-js": () => import("./../../../src/pages/events/index.js" /* webpackChunkName: "component---src-pages-events-index-js" */),
  "component---src-pages-events-zluri-verse-js": () => import("./../../../src/pages/events/zluri-verse.js" /* webpackChunkName: "component---src-pages-events-zluri-verse-js" */),
  "component---src-pages-features-access-beyond-scim-js": () => import("./../../../src/pages/features/access-beyond-scim.js" /* webpackChunkName: "component---src-pages-features-access-beyond-scim-js" */),
  "component---src-pages-features-access-requests-js": () => import("./../../../src/pages/features/access-requests.js" /* webpackChunkName: "component---src-pages-features-access-requests-js" */),
  "component---src-pages-features-api-js": () => import("./../../../src/pages/features/api.js" /* webpackChunkName: "component---src-pages-features-api-js" */),
  "component---src-pages-features-app-discovery-js": () => import("./../../../src/pages/features/app-discovery.js" /* webpackChunkName: "component---src-pages-features-app-discovery-js" */),
  "component---src-pages-features-automate-onboarding-and-offboarding-js": () => import("./../../../src/pages/features/automate-onboarding-and-offboarding.js" /* webpackChunkName: "component---src-pages-features-automate-onboarding-and-offboarding-js" */),
  "component---src-pages-features-contract-management-js": () => import("./../../../src/pages/features/contract-management.js" /* webpackChunkName: "component---src-pages-features-contract-management-js" */),
  "component---src-pages-features-contractor-access-management-js": () => import("./../../../src/pages/features/contractor-access-management.js" /* webpackChunkName: "component---src-pages-features-contractor-access-management-js" */),
  "component---src-pages-features-google-workspace-automation-js": () => import("./../../../src/pages/features/google-workspace-automation.js" /* webpackChunkName: "component---src-pages-features-google-workspace-automation-js" */),
  "component---src-pages-features-index-js": () => import("./../../../src/pages/features/index.js" /* webpackChunkName: "component---src-pages-features-index-js" */),
  "component---src-pages-features-license-cost-optimization-js": () => import("./../../../src/pages/features/license-cost-optimization.js" /* webpackChunkName: "component---src-pages-features-license-cost-optimization-js" */),
  "component---src-pages-features-saas-spend-management-js": () => import("./../../../src/pages/features/saas-spend-management.js" /* webpackChunkName: "component---src-pages-features-saas-spend-management-js" */),
  "component---src-pages-features-secure-deprovisioning-js": () => import("./../../../src/pages/features/secure-deprovisioning.js" /* webpackChunkName: "component---src-pages-features-secure-deprovisioning-js" */),
  "component---src-pages-features-shadow-it-js": () => import("./../../../src/pages/features/shadow-it.js" /* webpackChunkName: "component---src-pages-features-shadow-it-js" */),
  "component---src-pages-features-sox-compliance-js": () => import("./../../../src/pages/features/sox-compliance.js" /* webpackChunkName: "component---src-pages-features-sox-compliance-js" */),
  "component---src-pages-get-demo-js": () => import("./../../../src/pages/get-demo.js" /* webpackChunkName: "component---src-pages-get-demo-js" */),
  "component---src-pages-get-demo-testing-js": () => import("./../../../src/pages/get-demo-testing.js" /* webpackChunkName: "component---src-pages-get-demo-testing-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-index-js": () => import("./../../../src/pages/integrations/index.js" /* webpackChunkName: "component---src-pages-integrations-index-js" */),
  "component---src-pages-integrations-template-js": () => import("./../../../src/pages/integrations-template.js" /* webpackChunkName: "component---src-pages-integrations-template-js" */),
  "component---src-pages-lp-access-management-js": () => import("./../../../src/pages/lp/access-management.js" /* webpackChunkName: "component---src-pages-lp-access-management-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-on-demand-index-js": () => import("./../../../src/pages/on-demand/index.js" /* webpackChunkName: "component---src-pages-on-demand-index-js" */),
  "component---src-pages-our-story-js": () => import("./../../../src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-partners-activate-a-deal-js": () => import("./../../../src/pages/partners/activate-a-deal.js" /* webpackChunkName: "component---src-pages-partners-activate-a-deal-js" */),
  "component---src-pages-partners-index-js": () => import("./../../../src/pages/partners/index.js" /* webpackChunkName: "component---src-pages-partners-index-js" */),
  "component---src-pages-press-kit-js": () => import("./../../../src/pages/press-kit.js" /* webpackChunkName: "component---src-pages-press-kit-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-reseller-catalog-js": () => import("./../../../src/pages/reseller-catalog.js" /* webpackChunkName: "component---src-pages-reseller-catalog-js" */),
  "component---src-pages-resource-hub-for-cios-js": () => import("./../../../src/pages/resource-hub-for-cios.js" /* webpackChunkName: "component---src-pages-resource-hub-for-cios-js" */),
  "component---src-pages-return-on-investment-calculator-js": () => import("./../../../src/pages/return-on-investment-calculator.js" /* webpackChunkName: "component---src-pages-return-on-investment-calculator-js" */),
  "component---src-pages-saas-buying-js": () => import("./../../../src/pages/saas-buying.js" /* webpackChunkName: "component---src-pages-saas-buying-js" */),
  "component---src-pages-saas-management-js": () => import("./../../../src/pages/saas-management.js" /* webpackChunkName: "component---src-pages-saas-management-js" */),
  "component---src-pages-saaswhispers-about-js": () => import("./../../../src/pages/saaswhispers/about.js" /* webpackChunkName: "component---src-pages-saaswhispers-about-js" */),
  "component---src-pages-saaswhispers-episodes-js": () => import("./../../../src/pages/saaswhispers/episodes.js" /* webpackChunkName: "component---src-pages-saaswhispers-episodes-js" */),
  "component---src-pages-saaswhispers-index-js": () => import("./../../../src/pages/saaswhispers/index.js" /* webpackChunkName: "component---src-pages-saaswhispers-index-js" */),
  "component---src-pages-security-js": () => import("./../../../src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-solutions-biotech-js": () => import("./../../../src/pages/solutions/biotech.js" /* webpackChunkName: "component---src-pages-solutions-biotech-js" */),
  "component---src-pages-solutions-gaming-js": () => import("./../../../src/pages/solutions/gaming.js" /* webpackChunkName: "component---src-pages-solutions-gaming-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-thank-you-meeting-booked-js": () => import("./../../../src/pages/thank-you-meeting-booked.js" /* webpackChunkName: "component---src-pages-thank-you-meeting-booked-js" */),
  "component---src-pages-webinars-all-js": () => import("./../../../src/pages/webinars/all.js" /* webpackChunkName: "component---src-pages-webinars-all-js" */),
  "component---src-pages-webinars-index-js": () => import("./../../../src/pages/webinars/index.js" /* webpackChunkName: "component---src-pages-webinars-index-js" */),
  "component---src-pages-webinars-on-demand-js": () => import("./../../../src/pages/webinars/on-demand.js" /* webpackChunkName: "component---src-pages-webinars-on-demand-js" */),
  "component---src-pages-webinars-product-demo-registration-js": () => import("./../../../src/pages/webinars/product-demo-registration.js" /* webpackChunkName: "component---src-pages-webinars-product-demo-registration-js" */),
  "component---src-pages-whitepaper-js": () => import("./../../../src/pages/whitepaper.js" /* webpackChunkName: "component---src-pages-whitepaper-js" */),
  "component---src-pages-whitepaper-saas-management-report-js": () => import("./../../../src/pages/whitepaper/saas-management-report.js" /* webpackChunkName: "component---src-pages-whitepaper-saas-management-report-js" */),
  "component---src-pages-zluri-vs-torii-js": () => import("./../../../src/pages/zluri-vs-torii.js" /* webpackChunkName: "component---src-pages-zluri-vs-torii-js" */),
  "component---src-pages-zluri-vs-zylo-js": () => import("./../../../src/pages/zluri-vs-zylo.js" /* webpackChunkName: "component---src-pages-zluri-vs-zylo-js" */),
  "component---src-templates-academy-post-js": () => import("./../../../src/templates/academy-post.js" /* webpackChunkName: "component---src-templates-academy-post-js" */),
  "component---src-templates-blog-categories-js": () => import("./../../../src/templates/blog-categories.js" /* webpackChunkName: "component---src-templates-blog-categories-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/case-study.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-cios-category-js": () => import("./../../../src/templates/cios-category.js" /* webpackChunkName: "component---src-templates-cios-category-js" */),
  "component---src-templates-cios-js": () => import("./../../../src/templates/cios.js" /* webpackChunkName: "component---src-templates-cios-js" */),
  "component---src-templates-comparision-v-2-js": () => import("./../../../src/templates/comparision-v2.js" /* webpackChunkName: "component---src-templates-comparision-v-2-js" */),
  "component---src-templates-comparison-js": () => import("./../../../src/templates/comparison.js" /* webpackChunkName: "component---src-templates-comparison-js" */),
  "component---src-templates-comparison-new-js": () => import("./../../../src/templates/comparison-new.js" /* webpackChunkName: "component---src-templates-comparison-new-js" */),
  "component---src-templates-episode-js": () => import("./../../../src/templates/episode.js" /* webpackChunkName: "component---src-templates-episode-js" */),
  "component---src-templates-integration-categories-js": () => import("./../../../src/templates/integration-categories.js" /* webpackChunkName: "component---src-templates-integration-categories-js" */),
  "component---src-templates-integration-js": () => import("./../../../src/templates/integration.js" /* webpackChunkName: "component---src-templates-integration-js" */),
  "component---src-templates-integration-new-js": () => import("./../../../src/templates/integration-new.js" /* webpackChunkName: "component---src-templates-integration-new-js" */),
  "component---src-templates-live-events-js": () => import("./../../../src/templates/live-events.js" /* webpackChunkName: "component---src-templates-live-events-js" */),
  "component---src-templates-policy-js": () => import("./../../../src/templates/policy.js" /* webpackChunkName: "component---src-templates-policy-js" */),
  "component---src-templates-reseller-all-js": () => import("./../../../src/templates/reseller-all.js" /* webpackChunkName: "component---src-templates-reseller-all-js" */),
  "component---src-templates-reseller-home-category-js": () => import("./../../../src/templates/reseller-home-category.js" /* webpackChunkName: "component---src-templates-reseller-home-category-js" */),
  "component---src-templates-resellers-country-js": () => import("./../../../src/templates/resellers-country.js" /* webpackChunkName: "component---src-templates-resellers-country-js" */),
  "component---src-templates-top-picks-post-js": () => import("./../../../src/templates/top-picks-post.js" /* webpackChunkName: "component---src-templates-top-picks-post-js" */),
  "component---src-templates-webinar-js": () => import("./../../../src/templates/webinar.js" /* webpackChunkName: "component---src-templates-webinar-js" */)
}

